@import '../../styles/helpers/index.scss';

.form-wrapper {
  &__inner {
    @include grid;

    background-color: map-get($colors, 'green');
    border-radius: 20px;
    padding: 100px 40px;

    @media screen and (max-width: $desktop-sm) {
      padding: 60px 20px;
    }

    .title {
      h2 {
        font-size: 60px;
        font-weight: bold;
        line-height: 130%;
        max-width: 430px;

        @media screen and (max-width: $desktop-sm) {
          font-size: 32px;
        }
      }

      p {
        margin: 15px 0 0;
        font-size: 24px;
        line-height: 150%;
        max-width: 430px;

        @media screen and (max-width: $desktop-sm) {
          font-size: 18px;
        }
      }
    }

    .form {
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        input,
        textarea {
          &:not([type='submit']) {
            background: #fff;
            border: 1px solid #999;
            border-radius: 20px;
            height: 45px;
            padding: 0 15px;

            outline: none;

            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            line-height: 14px;

            &::placeholder {
              font-family: inherit;
              color: #999;
            }
          }
        }

        textarea {
          height: 120px !important;
          padding: 15px !important;
          resize: none;
        }
      }
    }
  }
}
