@import '../../styles/helpers/index.scss';

.services {
  &__inner {
    @include grid;

    & > div {
      &:not(:first-of-type) {
        @include scale;
        background-color: map-get($colors, 'green');
        border-radius: 20px;

        padding: 70px 30px;

        header {
          height: 80px;
          width: 100%;
          @include align-center;

          .icon {
            height: inherit;

            img {
              object-fit: cover;
              height: inherit;
              width: auto;
            }
          }

          p {
            font-weight: bold;
            font-size: 30px;
            padding-left: 40px;
          }
        }

        p {
          margin: 25px 0 0;
          font-size: 18px;
        }
      }
    }
  }
}
