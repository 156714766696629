@import '../../styles/helpers/index.scss';

.about {
  margin: -130px 0 0 !important;

  @media screen and (max-width: $desktop-sm) {
    margin: 30px 0 0 !important;
    overflow-x: hidden;
  }

  .container {
    position: relative;

    &::before {
      content: '';
      background-image: url('../../static/about-bg.svg');
      background-repeat: no-repeat;
      background-position: center;

      width: 1200px;
      height: 1180px;

      position: absolute;
      left: -4%;
      bottom: -50%;
      z-index: -1;

      @media screen and (max-width: $desktop-sm) {
        display: none;
      }
    }
  }

  &__grid {
    @include grid($row-gap: 20px, $column-gap: 20px, $columns: repeat(4, 1fr));

    &--item {
      @include scale;
      padding: 40px 30px;
      border-radius: 20px;
      background-color: map-get($colors, 'green');
      color: map-get($colors, 'text');

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media screen and (max-width: $desktop-sm) {
        min-height: 350px;
      }

      &:nth-child(odd) {
        background-color: map-get($colors, 'yellow');
      }

      .icon {
        height: 60px;

        img {
          height: inherit;
          width: auto;
          object-fit: cover;
        }
      }

      .title {
        max-width: 75%;
        margin: 20px 0 0;
        font-size: 30px;
        font-weight: bold;
      }

      p {
        margin: 20px 0 0;
        font-size: 18px;
      }
    }
  }

  &__result {
    margin: 120px 0 0;
    padding-left: calc(25% + 10px);

    @media screen and (max-width: $desktop-sm) {
      margin: 80px 0 0;
      padding-left: 0;
    }

    .caption {
      max-width: 680px;
      font-size: 30px;
      line-height: 120%;

      @media screen and (max-width: $desktop-sm) {
        max-width: unset;
      }

      span {
        color: map-get($colors, 'green');
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .counter {
      margin: 120px 0 0;
      @include grid($columns: repeat(3, 1fr));

      @media screen and (max-width: $desktop-sm) {
        margin: 60px 0 0;
        text-align: center;
      }

      p {
        color: #fff;
        font-size: 24px;
        line-height: 120%;

        &:first-of-type {
          color: map-get($colors, 'yellow');
          font-weight: bold;
          font-size: 80px;
          line-height: 155%;
        }
      }
    }
  }
}
