@import '../../styles/helpers/index.scss';

.reviews {
  &__list {
    margin: 45px 0 0;

    &--item {
      max-width: 400px;
      padding: 50px 30px;
      background-color: #fff;
      border-radius: 40px;
      color: map-get($colors, 'text');

      header {
        @include align-center;

        .icon {
          background-color: map-get($colors, 'yellow');
          height: 80px;
          width: 80px;

          border-radius: 50%;
          @include align-center;
          justify-content: center;

          i {
            color: #fff;
            font-size: 40px;
          }
        }

        .info {
          margin-left: 30px;
          display: flex;
          flex-direction: column;

          span {
            &:first-of-type {
              font-weight: bold;
              font-size: 18px;
              margin: 0 0 10px;
            }
          }
        }
      }

      .stars {
        margin: 30px 0;
        @include align-center;
        img {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 150%;
        min-height: 240px;
        overflow: scroll;
      }
    }
  }

  .slick-dots {
    bottom: -50px;

    li {
      button::before {
        content: '';
        background-color: #999;
        border-radius: 50px;
        width: 10px;
        height: 10px;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.slick-active {
        button {
          background-color: #fff;
          border: 1px solid map-get($colors, 'green');
          border-radius: 50%;
          &::before {
            background-color: map-get($colors, 'green');
          }
        }
      }
    }
  }
}
