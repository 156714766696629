@import '../../styles/helpers/index.scss';

.ticker {
  .section-title {
    text-align: center;
  }

  &__line {
    margin: 85px 0 0;
  }

  &__slider {
    &--slide {
      padding-right: 25px !important;
      height: 80px;
      display: flex !important;
      justify-content: space-between;
      @include align-center;

      span {
        white-space: nowrap;
        padding: 16px 20px;
        border-radius: 40px;
        border: 1px solid #fff;

        font-size: 24px;
        line-height: 120%;
        color: map-get($colors, 'green');
      }
    }
  }
}
