@import '../../styles/helpers/index.scss';

.page-header {
  padding: 60px 0 30px;

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  background-color: transparent;

  @media screen and (max-width: $tablet-sm) {
    padding: 30px 0;
  }

  &.shadow {
    background-color: map-get($colors, 'background');
    box-shadow: 0px 5px 15px 0px rgba(#000, 0.5);
    padding: 30px 0;

    .page-header__inner {
      align-items: center;
    }

    .page-header__logo {
      img {
        height: 45px;

        @media screen and (max-width: $tablet-sm) {
          height: 30px;
        }
      }

      .subtitle {
        margin: 15px 0 0;
        font-size: 16px;

        @media screen and (max-width: $tablet-sm) {
          margin: 0 0 0 15px;
          font-size: 14px;
        }
      }
    }
  }

  &__inner {
    @include space-between;
    align-items: flex-start;

    transition: all 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  &__logo {
    cursor: pointer;

    @media screen and (max-width: $tablet-sm) {
      @include space-between;
      align-items: center;
    }

    img {
      height: 120px;
      width: auto;
      object-fit: cover;

      transition: height 0.3s ease;

      @media screen and (max-width: $tablet-sm) {
        height: 30px;
      }
    }

    .subtitle {
      margin: 30px 0 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 120%;
      text-transform: uppercase;

      transition: all 0.3s ease;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 0 15px;
        font-size: 14px;
      }
    }
  }

  &__navigation {
    @media screen and (max-width: $tablet-sm) {
      margin: 15px auto 0;
    }

    ul {
      display: flex;

      li {
        cursor: pointer;

        &:not(:last-of-type) {
          margin: 0 15px 0 0;
        }

        a.active {
          color: map-get($colors, 'yellow');
        }
      }
    }
  }
}
