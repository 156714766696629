@import './helpers/variables.scss';
@import './helpers/mixins.scss';

html {
  height: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background');
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Nunito', sans-serif;
  color: #fff;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  #terms-of-use main,
  #privacy-policy main,
  #accessibility-statement main {
    padding: 70px 0 0;
  }

  main {
    position: relative;
    flex: 1 0 auto;
    transition: all 0.3s ease;
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  section {
    &:not(:first-of-type) {
      margin: 120px 0 0;

      @media screen and (max-width: $tablet-sm) {
        margin: 60px 0 0;
      }
    }
  }

  strong,
  b {
    font-weight: bold;
  }
}

.yellow-btn {
  padding: 15px 20px;
  background-color: map-get($colors, 'yellow');
  border: 1px solid transparent;
  color: map-get($colors, 'text');
  border-radius: 30px;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: map-get($colors, 'green');
    border-color: map-get($colors, 'yellow');
    color: #fff;
  }
}

.section-title {
  font-size: 60px;
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;

  @media screen and (max-width: $desktop-sm) {
    font-size: 28px;
  }

  span {
    color: map-get($colors, 'green');
  }
}
