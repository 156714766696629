@import '../../styles/helpers/index.scss';

.page-footer {
  padding: 150px 0 0;

  position: relative;

  &::before {
    content: '';

    background-image: url('../../static/footer-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    width: 100%;
    height: 1045px;

    position: absolute;
    bottom: -10%;

    z-index: -1;
  }

  a {
    color: #fff;
  }

  h2 {
    font-size: 30px;
    line-height: 120%;
    font-weight: bold;
    color: map-get($colors, 'green');
    text-transform: uppercase;

    @media screen and (max-width: $tablet-sm) {
      font-size: 24px;
      text-align: center;
    }
  }

  .links {
    @include align-center;
    margin: 0 0 15px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      margin: 15px 0 30px;
    }

    .email {
      font-size: 60px;
      font-weight: bold;

      @media screen and (max-width: $tablet-sm) {
        font-size: 24px;
      }
    }

    .fb,
    .yelp,
    .instagram {
      @include align-center;
      @include scale;
      justify-content: center;
      background-color: map-get($colors, 'yellow');
      border-radius: 50%;
      width: 60px;
      height: 60px;

      font-size: 40px;

      margin-left: 30px;

      @media screen and (max-width: $tablet-sm) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }

  .phone {
    font-size: 60px;
    font-weight: bold;

    @media screen and (max-width: $tablet-sm) {
      display: block;
      font-size: 24px;
      text-align: center;
    }
  }

  .flex-group {
    margin: 25px 0 0;

    @include space-between;
    align-items: flex-end;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  .address {
    font-size: 24px;

    @media screen and (max-width: $tablet-sm) {
      text-align: center;
    }
  }

  .logo {
    height: 120px;
  }
}
