@import '../../styles/helpers/index.scss';

.faq {
  margin: 0 !important;
  padding: 120px 0 0;
  background-image: url('../../static/faq-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .section-title {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
  }

  &__list {
    max-width: 800px;
    margin: 45px auto 0;
  }

  .accordion-wrapper {
    background-color: #fff;
    border-radius: 1.5em;
    color: map-get($colors, 'text');

    & + * {
      margin-top: 25px;
    }
  }

  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-title {
    font-weight: 800;
    cursor: pointer;
    padding: 45px 30px;
    @include space-between;
    align-items: center;
    font-size: 24px;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid currentColor;
    }

    &.open {
      &::after {
        content: '';
        border-top: 0;
        border-bottom: 5px solid;
      }
    }
  }

  .accordion-content {
    padding: 0 30px 45px;
    font-size: 18px;
  }
}
