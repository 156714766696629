@import '../../styles/helpers/index.scss';

.hero {
  margin: 0;

  background-image: url('../../static/hero-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__inner {
    min-height: 100vh;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media screen and (max-width: $desktop-sm) {
      justify-content: center;
      min-height: 800px;
    }

    .caption {
      padding: 0 0 17%;
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $desktop-sm) {
        max-width: unset;
      }

      p {
        max-width: 380px;
        font-size: 30px;
      }

      span {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        color: map-get($colors, 'green');
      }
    }
  }
}
